
import { defineComponent, ref, reactive } from 'vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import { getDeviceList, deleteDevice } from '@/api/single/device';
import removeFunc from '@/methods/remove-func';
import { ListActionItem, list } from '@/components/common/list';
import { overlayFormData } from '@/util/system';

export default defineComponent({
    components: {
        list,
        deviceStatus
    },
    props: {
        type: {
            type: String,
            default: 'project'
        },
        deviceType: {
            type: String,
            default: 'control'
        }
    },
    setup() {
        // searchKey选择项
        const searchKeyList = [{
            label: WordList.TabelPersonDeviceInHtmlMAC,
            name: 'MAC'
        }, {
            label: WordList.TabelPersonDeviceInHtmlLocation,
            name: 'Location'
        }, {
            label: WordList.MulListUpdateListTanleAccount,
            name: 'Sip'
        }, {
            label: WordList.TabelPersonDeviceInHtmlOwner,
            name: 'Owner'
        }, {
            label: WordList.TabelUserInHtmlRoleInstall,
            name: 'Install'
        }];

        const updateData = ref(0);

        // 搜索条件
        const formData = reactive({
            searchKey: 'MAC',
            searchValue: '',
            Brand: 'all',
            row: 10,
            page: 1
        });
        overlayFormData(formData);

        // 表头   迁移name 改成prop,增加 {
        //     name: 'op',
        //         label: WordList.CaptureListTanleAction
        // }
        const column = [{
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'Owner',
            label: WordList.TabelPersonDeviceInHtmlOwner
        }, {
            name: 'Install',
            label: WordList.TabelUserInHtmlRoleInstall
        }, {
            name: 'Status',
            label: WordList.MulListUpdateListTanleStatus,
            type: 'customize'
        }, {
            name: 'SipAccount',
            label: WordList.MulListUpdateListTanleAccount
        }, {
            name: 'BindingTime',
            label: WordList.PersonaldeviceListTanleBindingTime
        }];

        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: string}>({
            row: [],
            detail: [],
            total: '0'
        });

        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            getDeviceList(formData, (res: {row: object[];detail: object[];total: string}) => {
                tableData.row = res.row;
                tableData.detail = res.detail;
                tableData.total = res.total;
                updateData.value += 1;
            });
        };
        getThirdPartyDeviceApi();

        // 删除
        const deleteThirdPartyDeviceApi = (data: { ID: string }) => {
            removeFunc(WordList.TabelDeleteText, () => {
                deleteDevice({ ID: data.ID }, getThirdPartyDeviceApi);
            });
        };

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [{
            type: 'delete2',
            event(data: { ID: string }) {
                deleteThirdPartyDeviceApi(data);
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }];

        return {
            formData,
            searchKeyList,
            column,
            getThirdPartyDeviceApi,
            tableData,
            deleteThirdPartyDeviceApi,
            action,
            updateData
        };
    }
});
